<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height"
       viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"
  >
    <g transform="translate(-334.422 -177.939)">
      <g>
        <g>
          <path :fill="color" d="M336,181.7c5.1,5.1,10.1,10.1,15.2,15.2c8.1,8.1,16.2,16.2,24.2,24.2c1.9,1.9,3.7,3.7,5.6,5.6
            c1.1,1.1,2.9-0.6,1.8-1.8c-5.1-5.1-10.1-10.1-15.2-15.2c-8.1-8.1-16.2-16.2-24.2-24.2c-1.9-1.9-3.7-3.7-5.6-5.6
            C336.7,178.8,334.9,180.6,336,181.7L336,181.7z"
          />
        </g>
      </g>
      <g>
        <g>
          <path :fill="color" d="M381,180c-5.1,5.1-10.1,10.1-15.2,15.2c-8.1,8.1-16.2,16.2-24.2,24.2c-1.9,1.9-3.7,3.7-5.6,5.6
            c-1.1,1.1,0.6,2.9,1.8,1.8c5.1-5.1,10.1-10.1,15.2-15.2c8.1-8.1,16.2-16.2,24.2-24.2c1.9-1.9,3.7-3.7,5.6-5.6
            C383.9,180.6,382.2,178.8,381,180L381,180z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CloseSvg',
  props: {
    width: {
      type: String,
      required: false,
      default: '37px'
    },
    height: {
      type: String,
      required: false,
      default: '34px'
    },
    color: {
      type: String,
      required: false,
      default: '#000000'
    }
  }
}
</script>
